<template>
  <div v-if="proposal">
    <v-card flat class="auto elevation-4" height="100%">
      <v-card-text class="obj-title">
        <span class="title">{{ proposal.request.object }}</span>
        <v-btn x-small text color="accent" class="subaccent date-pub rounded-lg">24/OCT/2022</v-btn>
      </v-card-text>

      <v-card-text class="h-vac-content" v-html="proposal.request.content"></v-card-text>
      <v-card-text>
        <!-- <span class="secondary--text">Skills required:</span> <span class="error-text">No specified</span> -->
        <!-- <span class="ms-2 subprimary over-rounded">front dev </span>
          <span class="ms-2 subprimary over-rounded">full stack</span> -->
      </v-card-text>
      <v-card-actions>
        <v-btn small outlined color="secondary" class="subsecondary" @click="viewProposalsDetails">
          <v-icon small class="mr-2">{{ icons.mdiOpenInNew }}</v-icon>
          View more
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiOpenInNew } from '@mdi/js'

export default {
  props: {
    proposal: {
      type: Object,
      default: () => { }
    },
  },

  methods: {
    goToreferenceLink(value) {
      this.$router.push(link)
    },
    viewProposalsDetails() {
      this.$router.push({ name: 'proposal.details', params: {offer_title: this.proposal.request.object, offer_id: this.proposal.request.id} })
      // if (this.$store.getters.isSubscribed === true) {
      //   if (this.$store.getters.getSubscribtionLevel === 1) {
      //     this.$fire({
      //       title: 'Limited offer',
      //       text: "Your current offer is limited, subscribe to a new one.",
      //       timer: this.dialgtimer
      //     })
      //   }
      //   else { 
      //     this.$router.push({name: 'offer.details'})
      //   }
      // }
      // else {
      //   this.$fire({
      //     title: "Required subscription",
      //     text: "You must subscribe to an offer to access more features.",
      //     timer: this.dialgtimer
      //   }).then(() => {
      //     this.$router.push({ name: 'pricing', params: {} })
      //   })
      // }
    }
  },
  mount() {
    console.log(this.proposal)
  },
  setup() {
    return {
      icons: {
        mdiOpenInNew
      },
    }
  },
}
</script>

<style lang="scss">
//@override
.v-card {
  border-radius: 1em !important;
}

.obj-title {
  display: flex;
  flex-wrap: nowrap !important;
  justify-content: space-between;
  flex-direction: row;
}

.obj-title .title {
  font-size: 11pt !important;
  color: var(--v-secondary-base);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.obj-title .date-pub {
  font-size: 8pt !important;
  margin-left: 4px !important;
  padding: 0px 3px !important;
}

.h-vac-content {
  height: 4em !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-vacancie {
  width: 100%;
  display: block;
}
</style>