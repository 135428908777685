<template>
    <div class="slide-block" style="height: 100%">
        <hooper :settings="hooperSettings" style="height: 100%" ref="carousel" class="h-full">
            <slide v-for="(item, index) in items" :key="index" class="h-full slide-block-item">
                <user-sm :user="item" class="mr-12" v-if="component === 'user'"></user-sm>
                <!-- <card-vacancies :vacancies="item" class="mr-12" v-if="component === 'proposal'"></card-vacancies> -->
                <proposal-card :proposal="item" class="mr-12" v-if="component === 'proposal'"> </proposal-card>
                <company-card :company="item" class="mr-12" v-if="component === 'company'"></company-card>
                <!-- <job-card :value="item.value" class="mr-12" v-if="component === 'vacancies'"></job-card> -->
            </slide>
            <hooper-navigation slot="hooper-addons">
                <v-btn icon slot="hooper-prev" class="secondary ml-6">
                    <v-icon color="white">
                        {{ icons.mdiChevronLeft }}
                    </v-icon>
                </v-btn>
                <v-btn icon slot="hooper-next" class="secondary mr-6">
                    <v-icon color="white">
                        {{ icons.mdiChevronRight }}
                    </v-icon>
                </v-btn>
            </hooper-navigation>
        </hooper>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import UserSm from '@/views/pages/users/components/UserSm.vue'
import CardVacancies from './CardVacancies.vue';
import CompanyCard from '@/views/components/company/CompanyCard.vue'
import ProposalCard from '@/views/pages/components/ProposalCard.vue'
import JobCard from '@/views/dashboard/vacancies/components/JobCard.vue'
import 'hooper/dist/hooper.css';

export default {
    props: {
        component: {
            type: String,
            default: 'user'
        },
        items: {
            type: Array(),
            default: () => []
        },
        xl: {
            type: Number(),
            default: 5
        },
        lg: {
            type: Number(),
            default: 4
        },
        md: {
            type: Number(),
            default: 3
        },
        sm: {
            type: Number(),
            default: 1
        },
        speed: {
            type: Number(),
            default: 3000
        },
        autoPlay: {
            type: Boolean,
            default: true,
        },
        large: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Hooper, Slide,
        HooperNavigation,
        UserSm,
        CardVacancies,
        CompanyCard,
        JobCard,
        ProposalCard
    },
    data() {
        return {
            isImgLoaded: false,
            hooperSettings: {
                infiniteScroll: true,
                progress: true,
                autoPlay: this.autoPlay,
                itemsToShow: 1,
                playSpeed: this.speed,
                centerMode: true,
                breakpoints: {
                    320: {
                        centerMode: false,
                        itemsToShow: 1
                    },
                    481: {
                        centerMode: false,
                        itemsToShow: this.large === true ? 1 : 2
                    },
                    768: {
                        itemsToShow: 3,
                    },
                    769: {
                        centerMode: false,
                        itemsToShow: this.large === true ? 3 : 4
                    },
                    1000: {
                        itemsToShow: 3,
                    },
                    1025: {
                        centerMode: false,
                        itemsToShow: this.large === true ? 4 : 5
                    },
                    1201: {
                        itemsToShow: this.large === true ? 5 : 6
                    }
                }
            }
        }
    },

    methods: {
        loadImage() {
            this.isImgLoaded = true;
        },
    },
    computed: {
        setSpeeder() {
            // if(this.a)
        },
        showsNbItems() {
            if (this.$vuetify.breakpoint.xl) {
                if (this.xl) {
                    return this.xl
                }
                else {
                    return 6
                }
            }
            if (this.$vuetify.breakpoint.lg) {
                if (this.lg) {
                    return this.lg
                }
                else {
                    return 5
                }
            }
            if (this.$vuetify.breakpoint.md) {
                if (this.md) {
                    return this.md
                }
                else {
                    return 4
                }
            }
            if (this.$vuetify.breakpoint.sm) {
                return 2;
            }
            return 1;
        }
    },
    setup() {
        const icons = {
            mdiChevronLeft, mdiChevronRight
        }
        return {
            icons
        }
    },
}
</script>

<style lang="scss">
.slide-block {
    min-height: auto;
    height: 15.5em !important;
    //width: 11em !important;
}
</style>
