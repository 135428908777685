<template>
    <div class="proposal-item-card">
        <v-hover v-if="reoi">
            <v-card class="d-flex flex-row rounded-lg" max-width="100%" outlined @click="selectedCallForProposal">
                <div class="col-md-3 pa-4 d-flex flex-column">
                    <v-avatar class="primary mb-4" width="auto" height="20" tile></v-avatar>
                    <v-card-subtitle class="pa-0">
                        <span v-if="reoi.external === false">
                            <b class="secondary--text" v-if="reoi.enterprise">{{ reoi.enterprise.name }}</b>
                            <!-- {{reoi.location }},&nbsp; {{ reoi.country ? reoi.country.iso : '' }} -->
                        </span>
                        <span v-else>
                            <b class="secondary--text">{{ reoi.enterprise }}</b>
                        </span>
                    </v-card-subtitle>
                    <v-spacer></v-spacer>
                    <v-card-actions class="pa-0 mt-4">
                        <v-btn text class="subsecondary" color="secondary" small @click="selectedCallForProposal">View
                            more
                        </v-btn>
                    </v-card-actions>
                </div>
                <div class="col-md-9 pa-4">
                    <v-card-text class="d-flex flex-row flex-nowrap pb-2">
                        <template v-if="reoi.external === false">
                            <router-link
                                :to="{ name: 'proposal.details', params: { offer_id: reoi.id, offer_title: utils.Utils.replaceAll(reoi.title, ' ', '-') } }">
                                {{ reoi.title }} </router-link>
                        </template>
                        <template v-else>
                            <router-link
                                :to="{ name: 'proposal.external.details', params: { offer_title: reoi.link, keyword: reoi.keyword } }">
                                {{ reoi.title }} </router-link>
                        </template>

                    </v-card-text>
                    <v-card-subtitle>
                        <!-- <v-icon small class="mr-1">{{ icons.mdiMapMarkerOutline }}</v-icon> -->
                        <span>
                            Type: <b class="secondary--tex">{{ reoi.external === false ? reoi.requestype.libele :
                                    reoi.proposal
                            }}</b>
                        </span>
                    </v-card-subtitle>

                    <!-- <v-card-subtitle v-if="reoi.enterprise.country !== null">
                    <v-icon small class="mr-1">{{ icons.mdiMapMarkerOutline }}</v-icon>
                    <span v-if="reoi.external === false">
                        {{"(" + reoi.enterprise.country.iso + "), " + reoi.enterprise.country.name }}
                    </span>
                    <span v-else>
                        <b class="secondary--text">{{ reoi.location }}</b>
                    </span>
                </v-card-subtitle> -->
                    <!-- <div class="d-flex flex-row justify-start"> -->
                    <v-card-subtitle v-if="reoi.created_at" class="d-flex flex-row py-0">
                        <span>
                            <v-icon small class="mr-1">{{ icons.mdiCalendarRange }}</v-icon>
                            <span>Issue: </span>{{ reoi.created_at }}
                        </span>
                        <v-spacer></v-spacer>
                        <span v-if="reoi.expire_at" class="py-0">
                            <v-icon small class="mr-1" color="error">{{ icons.mdiCalendarRange }}</v-icon>
                            <span>Closing: </span>{{ reoi.expire_at }}
                        </span>
                    </v-card-subtitle>
                    <!-- </div> -->
                    <!-- <v-card-text class="d-flex flex-row">
                    <span>{{ reoi.external === false ? reoi.object : reoi.title }}</span> &nbsp;&nbsp;&nbsp;&nbsp;
                    <span>{{ reoi.created_at }}</span>
                </v-card-text> -->
                    <!-- <v-divider class="mx-4 mt-2"></v-divider>
                    <v-card-text v-html="reoi.external === false ? reoi.content : reoi.title"
                        class="proposal-item-content h-full" height="100%"></v-card-text> -->
                </div>
            </v-card>
        </v-hover>
        <v-skeleton-loader class="mx-auto" max-width="100%" type="article, card" v-else></v-skeleton-loader>
    </div>
</template>
<script>
// mdiVideoInputComponent
import { mdiClose, mdiCalendarRange, mdiDomain, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase, mdiMapMarkerOutline, mdiOpenInNew } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { Utils } from '@/plugins/Utils';
// import CheckRoleDialog from '@/views/components/CheckRoleDialog.vue';
export default {
    props: {
        reoi: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            loaderdata: false,
        }
    },
    methods: {
        selectedCallForProposal() {
            if (this.reoi.external === false) {
                this.$router.push({ name: 'proposal.details', params: { offer_id: this.reoi.id, offer_title: Utils.replaceAll(this.reoi.title, ' ', '-') } })
            } else {
                this.$router.push({ name: 'proposal.external.details', params: { offer_title: this.reoi.link.trim(), keyword: this.reoi.keyword } })
            }
        }

    },
    beforeMount() {
        // this.loadInitialProposals()
    },
    setup(props) {
        const icons = { mdiClose, mdiCalendarRange, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase, mdiDomain, mdiMapMarkerOutline, mdiOpenInNew }
        const utils = { Utils }
        return {
            icons, utils
        }
    }
}
</script>

<style lang="scss" scoped>
.proposal-item-card {
    cursor: pointer;

    .proposal-item-content {
        height: 50%;
    }
}
</style>