<template>
    <div class="empty-data-box">
        <v-icon color="primary" class="mx-auto" size="2.5em">{{ icons.mdiAlertCircle }}</v-icon>
        <div class="text-center primary--text">{{ showMessage(text) }}</div>
    </div>
</template>

<script>
import { mdiAlertCircle } from '@mdi/js'
export default {
    props: {
        text: {
            type: String,
            default: 'datas'
        }
    },
    data() {
        return {
            defaultfr: '',
        }
    },
    methods: {
        showMessage(msg) {
            var r_msg = 'No data yet'
            if (msg) {
                r_msg = 'No ' + msg + '  yet'
            }
            return r_msg
        }
    },
    setup() {
        const icons = { mdiAlertCircle }
        return {
            icons
        }
    },
}
</script>
<style lang="scss">
.empty-data-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 1em;
    margin: 1.2em;
}
</style>