<template>
    <v-dialog v-model="dialog" width="500" transition="dialog-bottom-transition" v-if="showbtn === true">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondaryAccent" class="secondary" outlined v-bind="attrs" v-on="on"
                @click="btnDialogClicked">
                {{actions.actionText}}
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="text-center pa-4">
                <span>{{ loadingChecked === true ? 'Waiting' : 'Access limited' }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="text-center pa-4">
                <v-progress-circular :size="70" :width="7" color="secondary" indeterminate
                    v-if="loadingChecked === true">
                </v-progress-circular>
                <p v-else>Please upgrade your subscribtion</p>
                <p>
                    <router-link :to="{ name: 'pricing' }"> Upgrade here</router-link>
                    <br />
                </p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="secondary" outlined @click="dialog = false" v-if="loadingChecked === false">Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>
<script>
import { VueEditor } from "vue2-editor";
import { mdiClose } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'

export default {
    props: {
        actions: {
            type: Object,
            required: () => { },
        },
    },
    data() {
        return {
            dialog: false,
            showbtn: false,
            typerole: '',
            rfpComment: '',
            loadingChecked: true,
            isAuthorized: false,
            icons: { mdiClose }
        }
    },
    components: {
        VueEditor
    },
    methods: {
        btnDialogClicked() {
            if (this.$store.getters.isDoubleAccount === true) {
            }
            else {
                Drequest.api(`pricingenterprise.authSupcription?id=${this.$store.getters.getUser.id}&role=${this.actions.role}`)
                    .get((response) => {
                        if (response.success === true) {
                            if (response.isPermitted === true) {
                                this.$router.push({
                                    name: this.actions.link, params: {
                                        offer_title: this.actions.title,
                                        offer_id: this.actions.k
                                    }
                                })
                            }
                            else { this.loadingChecked = false }
                        } else {
                            this.loadingChecked = false
                        }
                    })
                    .catch((err) => {
                        console.log("err")

                        this.loadingChecked = false
                    })
            }
        }
    },
    beforeMount() {

        // alert(this.$store.getters.getCompany.type.designation)
        Drequest.api(`lazyloading.enterprise?dfilters=on&id:eq=${this.$store.getters.getCompany.id}`)
            .get((response) => {
                if (response.success === true) {
                    this.typerole = response.listEntity[0].type.libele
                    // alert(this.typerole)
                    console.log(this.typerole)
                    if (this.typerole === 'IFIN' || this.typerole === 'IFOR') {
                        this.showbtn = false
                    } else {
                        this.showbtn = true
                    }
                } else {
                    this.loadingChecked = false
                }
            })
            .catch((err) => {
                console.log("err")

                this.loadingChecked = false
            })

    },
    setup(props) {
        return {}
    }
}
</script>