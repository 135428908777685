
export class Utils {
    constructor() {
    }

    /** Replace white space  */
    static replaceAll(str, find, replace) {
        var trim_str = str.trim()
        if((/\s/).test(trim_str) === true){
            return trim_str.replace(find, replace);
            // replace(find, replace);
        }
        return trim_str
    }
}